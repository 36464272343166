import flor_adele from "../images/companies_logos/flor_adele.svg"
import beverything_body from "../images/companies_logos/beverything_body.svg"
import black_cancer from "../images/companies_logos/black_cancer.png"
import nappily_natural from "../images/companies_logos/nappily_natural.svg"
import the_plant_plug from "../images/companies_logos/the_plant_plug.svg"
import swift_cafe from "../images/companies_logos/swift_cafe.svg"
import paint_it_off_events from "../images/companies_logos/paint_it_off_events.png"
import moms_at_om from "../images/companies_logos/moms_at_om.svg"
import knight_source_group from "../images/companies_logos/knight_source_group.jpg"
import woke_urban_apparel from "../images/companies_logos/woke_urban_apparel.jpg"
import cakes_by_chante from "../images/companies_logos/cakes_by_chante.png"
import the_willows from "../images/companies_logos/the_willows.jpg"
import teniandtayo from "../images/companies_logos/teniandtayoheads.jpg"
import sous_sus from "../images/companies_logos/sous_sus.jpg"
import re_elle from "../images/companies_logos/re_elle.jpg"

const companiesData = [
  {
    name: "Cakes by Chante",
    owner: "Chante Bernard",
    website: "https://www.cakesbychante.com/",
    socialNetworksUrl: ["https://www.instagram.com/cakesbychante/"],
    companyLogo: cakes_by_chante,
    imgClassName: "w-40",
  },
  {
    name: "Moms at OM",
    owner: "Keya Nkonoki",
    website: "https://www.momsatom.la/",
    socialNetworksUrl: ["https://www.instagram.com/w.om.care/"],
    companyLogo: moms_at_om,
    imgClassName: "",
  },
  {
    name: "FlorAdele",
    owner: "Jimmishea O’Lee",
    website: "https://www.floradele-flowers.com/",
    socialNetworksUrl: ["https://www.instagram.com/floradele_flowers/"],
    companyLogo: flor_adele,
    imgClassName: "",
  },
  {
    name: "The Plant Plug Los Angeles",
    owner: "Taylor Lindsey",
    website: "https://plantpluglosangeles.com/",
    socialNetworksUrl: ["https://www.instagram.com/theplantplugla/"],
    companyLogo: the_plant_plug,
    imgClassName: "",
  },
  {
    name: "BEverything Body",
    owner: "Bridgette Richard",
    website: "https://beverythingbody.com/",
    socialNetworksUrl: ["https://www.instagram.com/beverythingbody/"],
    companyLogo: beverything_body,
    imgClassName: "",
  },
  {
    name: "Swift Cafe",
    owner: "Kyndra McCrary",
    website: "https://swift-la.com/",
    socialNetworksUrl: ["https://www.instagram.com/theofficialswiftcafe/"],
    companyLogo: swift_cafe,
    imgClassName: "",
  },
  {
    name: "Nappily Naturals",
    owner: "Umaar Norwood & Sharon Williams-Norwood",
    website: "https://nappilynaturals.com/",
    socialNetworksUrl: ["https://www.instagram.com/nappilynaturals/"],
    companyLogo: nappily_natural,
    imgClassName: "",
  },
  {
    name: "Black Cancer Podcast",
    owner: "Jodi-Ann Burey",
    website: "https://www.blackcancer.co/",
    socialNetworksUrl: ["https://www.instagram.com/_black_cancer/?hl=en"],
    companyLogo: black_cancer,
    imgClassName: "w-33",
  },
  {
    name: "Paint It Off Events",
    owner: "Miesha Williams",
    website: "https://paintitoff.com/",
    socialNetworksUrl: ["https://www.instagram.com/paintitoff/"],
    companyLogo: paint_it_off_events,
    imgClassName: "w-45",
  },
  {
    name: "Knight Source Group",
    owner: "Goddess Dawn",
    website: "https://knightsourcegroup.com/",
    socialNetworksUrl: ["https://www.instagram.com/goddessdawn2u/"],
    companyLogo: knight_source_group,
    imgClassName: "w-45",
  },
  {
    name: "Woke Urban Apparel",
    owner: "Keith Puckett",
    website: "https://www.wokeurbanapparel.com/",
    socialNetworksUrl: ["https://www.instagram.com/wokeurbanapparel/"],
    companyLogo: woke_urban_apparel,
    imgClassName: "w-41",
  },
  {
    name: "The Willows",
    owner: "Aja Washington",
    website: "https://www.thewillowsco.com/",
    socialNetworksUrl: ["https://www.instagram.com/thewillowsco/"],
    companyLogo: the_willows,
    imgClassName: "w-41",
  },
  {
    name: "Teni and Tayo Creations",
    owner: "Omobola Imoisili",
    website: "https://www.teniandtayo.com/",
    socialNetworksUrl: ["https://www.instagram.com/teniandtayo/"],
    companyLogo: teniandtayo,
    imgClassName: "w-33",
  },
  {
    name: "SOUS SUS",
    owner: "Sharlena Bernard",
    website: "https://soussus.com/",
    socialNetworksUrl: ["https://www.instagram.com/soussusofficial/"],
    companyLogo: sous_sus,
    imgClassName: "w-33",
  },
  {
    name: "Body ReElle",
    owner: "Dakota Townson and Reginece Smith",
    website: "https://www.bodyreelle.com/",
    socialNetworksUrl: ["https://www.instagram.com/bodyreelle/"],
    companyLogo: re_elle,
    imgClassName: "w-33",
  }
]

export default companiesData
