import React from "react"
import CloseButton from "../atoms/CloseButton"
import ReactPlayer from "react-player/lazy"

const ResponsivePlayer = ({ isOpen, closePlayer }) => {
  return (
    <div
      className={`h-full w-full bg-black absolute top-0 left-0 z-30 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <CloseButton onClick={closePlayer} />
      <ReactPlayer
        url="https://player.vimeo.com/video/682104497"
        playing={isOpen}
        controls={true}
        width="100%"
        height="100%"
        style={{ width: "100%", height: "auto" }}
        onEnded={closePlayer}
        onError={closePlayer}
      />
    </div>
  )
}

export default ResponsivePlayer
