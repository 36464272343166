import React from "react"

const TestimonialCard = ({ author, brand, speech }) => {
  return (
    <figure className="mb-24 laptop-standard:mb-26 hover:cursor-move active:cursor-move">
      <blockquote className="text-3lg tracking-tight leading-6 laptop-standard:leading-13 laptop-standard:text-3xl">
        {speech}
      </blockquote>
      <figcaption className="text-3lg leading-6 tracking-tight flex flex-col laptop-standard:flex-row laptop-standard:text-xl laptop-standard:gap-2 laptop-standard:-mt-14 laptop-standard:leading-13">
        <p>&mdash; {author},</p>
        <cite>{brand}</cite>
      </figcaption>
    </figure>
  )
}

export default TestimonialCard
