import { Link } from "gatsby"
import React from "react"
import CTAButton from "../atoms/CtaButton"

const CTASection = () => {
  return (
    <section className="bg-primaryYellow px-8 py-25 text-primaryGreen text-center flex flex-col items-center gap-13 relative cta-section mt-15 laptop-standard:mt-45">
      <div>
        <h2 className="text-6lg tracking-tight laptop-standard:text-5xl">
          Are you ready to apply?
        </h2>
        <p className="text-lg tracking-tight mt-4">
          We’ll ask you a few quick questions to get you started.
        </p>
      </div>
      <Link to="/form" className="relative z-20">
        <CTAButton caption={"Apply Now"} />
      </Link>
    </section>
  )
}

export default CTASection
