import React from "react"
import CompaniesInBloomSection from "../organism/CompaniesInBloomSection"
import CTASection from "../organism/CTASection"
import HeroSection from "../organism/HeroSection"
import HotItWorksSection from "../organism/HotItWorksSection"
import PressSection from "../organism/PressSection"
import TestimonialsSection from "../organism/TestimonialsSection"
import AlumniSection from "../organism/AlumniSection"

const LandingPageBody = () => {
  return (
    <main className="min-h-screen flex flex-col gap-25 laptop-standard:gap-45 overflow-hidden">
      <HeroSection />
      <HotItWorksSection />
      <CompaniesInBloomSection />
      <TestimonialsSection />
      <PressSection />
      <AlumniSection />
      <CTASection />
    </main>
  )
}

export default LandingPageBody
