import React from "react"
import SocialNetworkButton from "./SocialNetworkButton"

const CompanyCard = ({
  name,
  owner,
  website,
  socialNetworks,
  companyLogo,
  className,
  imgClassName,
}) => {
  return (
    <div
      className={`border-2 rounded-md p-8 border-primaryGreen min-w-[315px] h-[400px] flex flex-col ${
        className ? className : ""
      }`}
    >
      <div className="min-h-[180px] -mt-8">
        <img
          className={`relative company-card-logo -z-10 ${imgClassName}`}
          src={companyLogo}
          alt={`${name} logo`}
        />
      </div>

      <div className="h-full flex flex-col justify-between">
        <div>
          <h5 className="text-2lg tracking-tight font-medium leading-5">
            {owner}
          </h5>
          <p className="text-md tracking-tight">{`Owner of ${name}`}</p>
        </div>
        <div className="text-lg underline flex gap-6 items-center ">
          {website && (
            <a
              className="hover:text-primaryGreen"
              href={website}
              target="_blank"
              rel="noreferrer"
            >
              Visit Site
            </a>
          )}
          {socialNetworks.map((sn, i) => (
            <SocialNetworkButton key={i} url={sn} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CompanyCard
