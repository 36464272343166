import React from "react"
import pressData from "../../modules/pressData"
import PressCard from "../molecules/PressCard"

const PressSection = () => {
  return (
    <section className="px-8 laptop-standard:px-16">
      <h3 className="border-t-2 pt-7 text-5lg tracking-tight">Press</h3>
      <div className="grid grid-cols-1 gap-13 laptop-standard:grid-cols-4 content-center laptop-standard:gap-5">
        {pressData.map((p, i) => (
          <PressCard
            key={i}
            url={p.url}
            headline={p.headline}
            ctaText={p.ctaText}
            logo={p.logo}
          />
        ))}
      </div>
    </section>
  )
}

export default PressSection
