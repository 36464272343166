import React from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import CompaniesContainer from "../molecules/CompaniesContainer"

const CompaniesInBloomSection = () => {
  return (
    <section className="px-8 laptop-standard:px-40">
      <h2 className="text-primaryGreen text-6lg laptop-standard:text-5xl mb-7">
        Companies in Bloom
      </h2>
      <ScrollContainer className="hover:cursor-move active:cursor-move -mx-8 laptop-standard:-mx-40">
        <CompaniesContainer />
      </ScrollContainer>
    </section>
  )
}

export default CompaniesInBloomSection
