import React from "react"
import pressData from "../../modules/alumniData"
import PressCard from "../molecules/PressCard"

const AlumniSection = () => {
  return (
    <section className="px-8 laptop-standard:px-16 -mt-5 laptop-standard:-mt-35">
      <h3 className="text-5lg tracking-tight">Alumni News</h3>
      <div className="grid grid-cols-1 gap-13 laptop-standard:grid-cols-4 content-center laptop-standard:gap-5">
        {pressData.map((p, i) => (
          <PressCard
            key={i}
            url={p.url}
            headline={p.headline}
            ctaText={p.ctaText}
            logo={p.logo}
          />
        ))}
      </div>
    </section>
  )
}

export default AlumniSection
