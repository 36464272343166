import React from "react"

const HowItWorksHeader = () => {
  return (
    <div>
      <h2 className="text-6lg laptop-standard:text-5xl capitalize text-primaryGreen tracking-tighter">
        How it works
      </h2>
      <p className="text-lg tracking-tight laptop-standard:text-4lg mt-4 laptop-standard:mt-6 laptop-standard:w-4/5">
        The four-week program is designed with actionability in mind, structured
        toward brevity for immediate implementation and impact. Each week for
        2-3 hours, you’re invited to a different masterclass that will grow your
        marketing toolkit and pay dividends for you in the near and long term.
      </p>
    </div>
  )
}

export default HowItWorksHeader
