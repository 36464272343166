import React from "react"

const CoverText = () => {
  return (
    <div className="mt-25 w-11/12 px-8 laptop-standard:px-40 laptop-standard:mt-45">
      <p className="text-5lg leading-7 tracking-tight laptop-standard:text-3xl laptop-standard:leading-13 laptop-standard:tracking-normal">
        A free brand-building program created and produced by Deutsch LA,
        Blackness in Full Bloom helps local Black-owned businesses blossom and
        reach new heights through world-class marketing expertise and resources.
        With a proven track record of success, Deutsch LA is a leading
        advertising agency that works in partnership with some of the world’s
        most recognized brands.
      </p>
    </div>
  )
}

export default CoverText
