import React from "react"
import SeoComponent from "../components/atoms/SeoComponent"
import GlobalTemplate from "../components/templates/GlobalTemplate"
import LandingPageBody from "../components/templates/LandingPageBody"

const Home = () => {
  return (
    <>
      <GlobalTemplate ctaButton={true} stickyNavbar>
        <LandingPageBody />
      </GlobalTemplate>
    </>
  )
}

export default Home

export const Head = () => <SeoComponent />
