import React from "react"
import CreatingSocialContent from "../components/atoms/workshopLogos/CreatingSocialContentLogo"
import DesignAndVisualIdLogo from "../components/atoms/workshopLogos/DesignAndVisualIdLogo"
import MarketingStrategyLogo from "../components/atoms/workshopLogos/MarketingStrategyLogo"
import PaidMediaAndPrLogo from "../components/atoms/workshopLogos/PaidMediaAndPrLogo"

const workshopsData = [
  {
    title: "Marketing Strategy",
    description:
      "Marketing in social the way the big brands do. The role of your brand, and how to create consistency and appeal.",
    logo: <MarketingStrategyLogo />,
  },
  {
    title: "Paid Media & PR",
    description:
      "How to invest in your business by amplifying your messages in social and search. Learn how to use PR and press to put a multiplier on your reach.",
    logo: <PaidMediaAndPrLogo />,
  },
  {
    title: "Design & Visual ID",
    description:
      "How design plays a major role in overall brand development and audience engagement. Learn how to visually stand out in a crowded marketplace. ",
    logo: <DesignAndVisualIdLogo />,
  },
  {
    title: "Creating Social Content",
    description:
      "How to affordably and efficiently produce higher-quality assets for all your business and marketing needs.",
    logo: <CreatingSocialContent />,
  },
]

export default workshopsData
