import React from "react"
import ExternalLink from "../atoms/ExternalLink"

const PressCard = ({ url, headline, ctaText, logo }) => {
  return (
    <div className="flex flex-col text-lg">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full"
      >
        <div
          className={`sh-zoom w-full overflow-hidden ${
            url.match(/lasentinel.net/gi) && "laptop-standard:border-[1px]"
          }`}
        >
          {logo}
        </div>
      </a>
      <a href={url} target="_blank" rel="noopener noreferrer" className="mt-2">
        <p className="underline">{headline}</p>
        <p className="flex items-center gap-2">
          <span>{ctaText}</span>
          <span>
            <ExternalLink />
          </span>
        </p>
      </a>
    </div>
  )
}

export default PressCard
