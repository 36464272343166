import React from "react"
import CoverSection from "../molecules/CoverSection"
import CoverText from "../molecules/CoverText"

const HeroSection = () => {
  return (
    <section>
      <CoverSection />
      <CoverText />
    </section>
  )
}

export default HeroSection
