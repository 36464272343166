import React from "react"

const WorkshopCard = ({ logo, number, title, description }) => {
  return (
    <article className="flex flex-col items-center gap-4">
      <div className="px-8 w-auto laptop-standard:px-0 laptop-standard:h-[400px] flex items-center justify-center">
        {logo || "Workshop Logo"}
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-xs uppercase font-bold text-primaryGreen laptop-standard:text-[1.125rem]">
          {`Workshop ${number || 0}`}
        </p>
        <h2 className="text-6lg tracking-tighter font-bold text-primaryGreen laptop-standard:text-5xl">
          {title}
        </h2>
        <p className="text-lg tracking-tight laptop-standard:text-[1.625rem]">
          {description}
        </p>
      </div>
    </article>
  )
}

export default WorkshopCard
