import React from "react"
import companiesData from "../../modules/companiesData"
import CompanyCard from "../molecules/CompanyCard"

const CompaniesContainer = () => {
  const lastCompany = companiesData.length - 1
  return (
    <div className="flex items-center pl-8 laptop-standard:pl-40">
      {companiesData.map((c, i) => (
        <CompanyCard
          key={i}
          className={i === lastCompany ? "mr-8 laptop-standard:mr-40" : "mr-5"}
          companyLogo={c.companyLogo}
          name={c.name}
          owner={c.owner}
          socialNetworks={c.socialNetworksUrl}
          website={c.website}
          imgClassName={c.imgClassName}
        />
      ))}
      <div>&nbsp;</div>
    </div>
  )
}

export default CompaniesContainer
