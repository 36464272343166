import React from "react"

const CloseButton = ({ onClick }) => {
  return (
    <button
      className="absolute top-[10px] right-[10px]"
      type="button"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.672"
        height="17.672"
        viewBox="0 0 17.672 17.672"
        className="fill-[#fff]"
      >
        <g
          id="Grupo_14915"
          data-name="Grupo 14915"
          transform="translate(-1358.164 -786.164)"
        >
          <rect
            id="Rectángulo_4038"
            data-name="Rectángulo 4038"
            width="21.584"
            height="3.408"
            transform="translate(1358.165 801.426) rotate(-45)"
          />
          <rect
            id="Rectángulo_4039"
            data-name="Rectángulo 4039"
            width="21.584"
            height="3.408"
            transform="translate(1373.426 803.836) rotate(-135)"
          />
        </g>
      </svg>
    </button>
  )
}

export default CloseButton
