import React from "react"
import CakesByChante from "../components/atoms/pressLogos/CakesByChante"
import VoyageLALogo from "../components/atoms/pressLogos/VoyageLALogo"
import PlayaVistaMag from "../components/atoms/pressLogos/PlayaVistaMag"

const alumniData = [
  {
    headline: "Making an Impact: Cakes By Chanté",
    ctaText: "Watch on YouTube.",
    url: "https://www.youtube.com/watch?v=mf-Qha96KRE",
    logo: <CakesByChante />,
  },
  {
    headline: "Helping Black Businesses Bloom",
    ctaText: "Read on Playa Vista Magazine",
    url: "https://playavistadirect.com/helping-black-businesses-bloom/",
    logo: <PlayaVistaMag />,
  },
  {
    headline: "Hidden Gems: Meet Reginiece Smith & Dakota Townson of Body ReElle",
    ctaText: "Read on Voyage LA",
    url: "https://voyagela.com/interview/hidden-gems-meet-reginiece-dakota-smith-townson-of-body-reelle/",
    logo: <VoyageLALogo />,
  },
]

export default alumniData
