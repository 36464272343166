import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"

export const TestimonialCarouselDesktop = ({ children }) => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      pagination={{
        clickable: true,
        bulletClass: "custom-bullet",
        bulletActiveClass: "custom-active-bullet",
      }}
      spaceBetween={100}
      loop={true}
      loopAdditionalSlides={1}
      autoplay={{ disableOnInteraction: false }}
    >
      {Array.isArray(children) &&
        children.map((child, i) => <SwiperSlide key={i}>{child}</SwiperSlide>)}
    </Swiper>
  )
}

export const TestimonialCarouselMobile = ({ children }) => {
  return (
    <Swiper
      modules={[Pagination]}
      pagination={{
        clickable: true,
        bulletClass: "custom-bullet",
        bulletActiveClass: "custom-active-bullet",
      }}
      loop={true}
      spaceBetween={50}
      loopAdditionalSlides={1}
    >
      {Array.isArray(children) &&
        children.map((child, i) => <SwiperSlide key={i}>{child}</SwiperSlide>)}
    </Swiper>
  )
}
