const testimonialsData = [
  {
    speech:
      "The program gave my business the boost it desperately needed amidst all the pandemic pivots. The team was incredibly knowledgeable, generous, and thoughtful. I am still going back to my valuable notes from their presentations as I take my business through a massive rebrand.",
    author: "Keya Nkonoki",
    brand: "Moms at OM",
  },
  {
    speech:
      "I am so grateful to Blackness in Full Bloom! I’ve learned so much great information and skills to utilize in my business and social media! Also, everyone was so kind and helpful. What an amazing program to have been in!",
    author: "Milan Bell Watts",
    brand: "Urban Doula",
  },
  {
    speech:
      "Blackness in Full Bloom was time well spent! The sessions are focused and created the right space to explore new possibilities for my work and audience—now and in the future.",
    author: "Jodi-Ann Burey",
    brand: "Black Cancer Podcast",
  },
  {
    speech:
      "Blackness in Full Bloom was very instrumental in helping me fine-tune my brand's creative direction. What I learned in the program was invaluable.",
    author: "Aja Washington, Founder",
    brand: "The Willows",
  },
]

export default testimonialsData
