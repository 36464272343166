import React from "react"
import workshopsData from "../../modules/workshopData"
import HowItWorksHeader from "../molecules/HowItWorksHeader"
import WorkshopCard from "../molecules/WorkshopCard"

const HotItWorksSection = () => {
  return (
    <section className="px-8 laptop-standard:px-40">
      <HowItWorksHeader />
      <div className="grid grid-cols-1 gap-25 mt-25 laptop-standard:gap-x-20 laptop-standard:grid-cols-2 laptop-standard:justify-center">
        {workshopsData.map((w, i) => (
          <WorkshopCard
            key={i}
            logo={w.logo}
            number={i + 1}
            title={w.title}
            description={w.description}
          />
        ))}
      </div>
    </section>
  )
}

export default HotItWorksSection
