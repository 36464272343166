import React from "react"
import AdEdgeLogo from "../components/atoms/pressLogos/AdEdgeLogo"
import BlackNLaLogo from "../components/atoms/pressLogos/BlackNLaLogo"
import SentinelLogo from "../components/atoms/pressLogos/SentinelLogo"
import TheArgonautLogo from "../components/atoms/pressLogos/TheArgonautLogo"

import Fox11 from "../components/atoms/pressLogos/Fox11"

const pressData = [
  {
    headline: "Advancing Black and Brown Entrepreneurship",
    ctaText: "Watch on YouTube",
    url: "https://www.youtube.com/watch?v=a5la3hfBwG0",
    logo: <Fox11 />,
  },
  {
    headline: "Helping Businesses Bloom.",
    ctaText: "Read on The Argonaut.",
    url: "https://www.argonautnews.com/news/helping-businesses-bloom/article_5924945d-4a4f-5791-a62c-8d583d1fa9e9.html",
    logo: <TheArgonautLogo />,
  },
  {
    headline: "Deutsch L.A. backs Black entrepreneurs.",
    ctaText: "Read on AdAge.",
    url: "https://adage.com/article/agency-news/women-come-first-international-womens-day-and-deutsch-la-backs-black-entrepreneurs-agency-brief/2320001",
    logo: <AdEdgeLogo />,
  },
  {
    headline: "Free Brand-Building Program for Black-Owned Businesses",
    ctaText: "Read on BlackNLA",
    url: "https://blacknla.com/2021/03/05/local-ad-agency-hosts-free-brand-building-program-for-black-owned-businesses/",
    logo: <BlackNLaLogo />,
  },
  {
    headline:
      "Blackness in Full Bloom: Building Black-owned Businesses from the Ground Up",
    ctaText: "Read on LA Sentinel",
    url: "https://lasentinel.net/blackness-in-full-bloom-building-black-owned-businesses-from-the-ground-up.html",
    logo: <SentinelLogo />,
  },

]

export default pressData
