import React, { useState } from "react"
import BIFBMainLogo from "../atoms/BIFBMainLogo"
import CTAButton from "../atoms/CtaButton"
import ResponsivePlayer from "./ResponsivePlayer"

const CoverSection = () => {
  const [openPlayer, setOpenPlayer] = useState(false)

  const handleOpen = () => {
    setOpenPlayer(true)
  }

  const handleClose = () => {
    setOpenPlayer(false)
  }

  return (
    <div className="bg-primaryGreen p-8 fruit-background relative laptop-standard:p-16">
      <div className="figures-background">
        <div className="relative h-70 hero-background  z-20 laptop-standard:h-82">
          <div className="flex flex-col justify-end items-center px-7 py-8 gap-8 laptop-standard:gap-13 laptop-standard:py-13 h-full">
            <BIFBMainLogo />
            <CTAButton caption="Watch Video" onClick={handleOpen} />
          </div>
        </div>
      </div>
      <ResponsivePlayer isOpen={openPlayer} closePlayer={handleClose} />
    </div>
  )
}

export default CoverSection
