import React from "react"
import useMobileScreen from "../../hooks/useMobileScreen"
import testimonialsData from "../../modules/testimonialsData"
import TestimonialCard from "../molecules/TestimonialCard"
import {
  TestimonialCarouselDesktop,
  TestimonialCarouselMobile,
} from "../molecules/TestimonialCarousel"

const TestimonialsSection = () => {
  const isMobileScreen = useMobileScreen()

  return (
    <section className="px-8 laptop-standard:px-40">
      <h2 className="text-primaryGreen text-6lg laptop-standard:text-5xl">
        Testimonials
      </h2>
      <div className="mt-9 laptop-standard:mt-16">
        {isMobileScreen ? (
          <TestimonialCarouselMobile>
            {testimonialsData.map((t, i) => (
              <TestimonialCard
                key={i}
                author={t.author}
                brand={t.brand}
                speech={t.speech}
              />
            ))}
          </TestimonialCarouselMobile>
        ) : (
          <TestimonialCarouselDesktop>
            {testimonialsData.map((t, i) => (
              <TestimonialCard
                key={i}
                author={t.author}
                brand={t.brand}
                speech={t.speech}
              />
            ))}
          </TestimonialCarouselDesktop>
        )}
      </div>
    </section>
  )
}

export default TestimonialsSection
